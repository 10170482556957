import { LedTape } from "../database/Products";
import { LedTapeRun, LedTapeStrip } from "./RunCalculations";
import { Ft2In, Mm2Ft, Mm2In } from "./UomConversion";

type Run = {
  totalFeet: number;
  strips: number;
  inchesPerStrip: number;
  wattsPerStrip: number;
}

export const getBuildDetails = (qty: number, cutIntervalMm: number, strips: LedTapeStrip[], includeWattage = false) => {
  const uniqueIntervalLengths = Array.from(new Set(strips.map(s => s.cutIntervals)));
  const runs: Run[] = [];
  uniqueIntervalLengths.forEach(cutIntervals => {
    const relevantStrips = strips.filter(str => str.cutIntervals === cutIntervals);
    const qty = relevantStrips.length;
    const wattsPerStrip = relevantStrips[0].watts;
    const newRun: Run = {
      totalFeet: Mm2Ft((qty * cutIntervals) * (cutIntervalMm ?? 0)),
      inchesPerStrip: (Mm2In(cutIntervals * cutIntervalMm)),
      strips: qty,
      wattsPerStrip: wattsPerStrip
    };
    runs.push(newRun);
  });
  let buildDetails = ``;
  runs.forEach((r, index) => {
    if(index !== 0) buildDetails += `\n`;
    buildDetails += `- ${qty} @ ${r.totalFeet.toFixed(2)}FT (${r.strips * qty} @ ${toNearest16thInch(r.inchesPerStrip)})`;
    if(includeWattage){
      const wattage = r.wattsPerStrip.toFixed(2);
      buildDetails += ` ~${wattage}w per lead`;
    }
  });
  return buildDetails;
}

export const getBuildDetailsForBulkReel = (bulkReelLength_Ft: number, qty: number) => {
  return `${qty} @ ${bulkReelLength_Ft.toFixed(2)}FT (${qty} @ ${toNearest16thInch(Ft2In(bulkReelLength_Ft))})`;
}

export function toNearest16thInch(measurementInches: number): string {
  // Extract the whole number and fractional part
  const wholeNumber = Math.floor(measurementInches);
  const fraction = measurementInches - wholeNumber;

  // Convert the fractional part to the nearest 16th
  const nearestSixteenth = Math.round(fraction * 16);

  // Reduce the fraction
  let numerator = nearestSixteenth;
  const denominator = 16;
  const divisor = gcd(numerator, denominator);

  numerator = numerator / divisor;
  const reducedDenominator = denominator / divisor;
  
  // Determine the fractional string representation
  let fractionString = "";
  if (numerator === 0) {
      fractionString = "";
  } else if (numerator === reducedDenominator) {
      fractionString = ""; // Handle rounding up to the next whole number
  } else {
      fractionString = `-${numerator}/${reducedDenominator}`;
  }

  // If the fraction rounded up to a whole number, increment the whole number
  const finalWholeNumber = numerator === reducedDenominator ? wholeNumber + 1 : wholeNumber;

  return `${finalWholeNumber}${fractionString}"`;
}

export function gcd(a: number, b: number): number {
  while (b !== 0) {
      [a, b] = [b, a % b];
  }
  return a;
}