import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import StyledNumberInput from "../../../../components/StyledNumberInput";
import { LedTape } from "../../../../database/Products";
import Fade from "@mui/material/Fade";
import { Run } from "../types";
import { CalculateRunLine } from "../utils";
import { getBuildDetails } from "../../../../global_functions/BuildDetails";
import { CalculateRuns, LedTapeRun } from "../../../../global_functions/RunCalculations";

type AssemblyHelperProps = {
  ledTape: LedTape | null;
}

const AssemblyHelper = ({ ledTape }: AssemblyHelperProps) => {
  const colors = useTheme().colors;

  const [feetInput, setFeetInput] = useState<number>(0);
  const [inchesInput, setInchesInput] = useState<number>(0);
  const [endcapMmInput, setEndcapMmInput] = useState<number>(2);
  const [copiedDesc, setCopiedDesc] = useState(false);

  const handleFeetChange = (value: number) => {
    setFeetInput(value);
  };

  const handleInchesChange = (value: number) => {
    setInchesInput(value);
  };

  const handleEndcapThicknessChange = (value: number) => {
    setEndcapMmInput(value);
  };

  const handleOnClickDesc = (value: string) => {
    navigator.clipboard.writeText(value);
    setCopiedDesc(true);
  };

  const handleOnCloseCopyTooltip = () => {
    setCopiedDesc(false);
  };

  const overallLength_mm = Math.round((feetInput * 12 * 25.4) + (inchesInput * 25.4));
  const overallLength_Ft = feetInput + (inchesInput / 12);
  const overallLength_In = (feetInput * 12) + inchesInput;
  const overallLength_Combined = `${Math.floor(feetInput)}'-${((feetInput - Math.floor(feetInput)) * 12) + inchesInput}"`;

  const allowedTapeSpace_mm = overallLength_mm - (endcapMmInput * 2);
  const cutInterval_mm = ledTape?.specs.cut_interval_mm ?? 0;
  const cutIntervalsAllowed = Math.floor(allowedTapeSpace_mm / cutInterval_mm);
  const cutLength_mm = cutIntervalsAllowed * cutInterval_mm;
  const totalDeadSpace = allowedTapeSpace_mm - cutLength_mm;

  const endcapPercentage = (((endcapMmInput * 2) / overallLength_mm) * 100).toFixed(2) + '%';
  const deadzonePercentage = (((totalDeadSpace / 2) / overallLength_mm) * 100).toFixed(2) + '%';
  const tapePercentage = ((cutLength_mm / overallLength_mm) * 100).toFixed(2) + '%';

  const run: LedTapeRun | null = ledTape ? CalculateRuns(ledTape, overallLength_mm, 'Balanced', 'Down', false) : null;
  const buildDetails = run ? getBuildDetails(1, ledTape?.specs.cut_interval_mm ?? 0, run.strips, true) : null;
  const displayedDetails = buildDetails ? `${buildDetails}\n** O.A Fixture length ${overallLength_Combined} **` : null;

  return (
    <Box marginBottom={5}>
      <Box display='flex' flexDirection='row' gap={2} marginBottom={2}>
        <StyledNumberInput
          label="O.A. Feet"
          type="number"
          value={feetInput > 0 ? feetInput : ''}
          onChange={(e) => handleFeetChange(Number(e.target.value))}
          autoComplete="off"
        />
        <StyledNumberInput
          label="O.A. Inches"
          type="number"
          value={inchesInput > 0 ? inchesInput : ''}
          onChange={(e) => handleInchesChange(Number(e.target.value))}
          autoComplete="off"
        />
        <StyledNumberInput
          label="Endcap Thickness (mm)"
          type="number"
          value={endcapMmInput > 0 ? endcapMmInput : ''}
          onChange={(e) => handleEndcapThicknessChange(Number(e.target.value))}
          sx={{ maxWidth: 'none' }}
          autoComplete="off"
        />
      </Box>
      {
        ledTape && feetInput + inchesInput > 0 && 
        <>
        <Box>
          <Typography><b>Overall Length</b>: {overallLength_Ft.toFixed(2)} FT | {overallLength_In.toFixed(2)} IN | {overallLength_mm} mm | {overallLength_Combined}</Typography>
          <Typography><b>Allowed Tape Space</b>: {allowedTapeSpace_mm} mm</Typography>
          <Typography><b>Max Tape Length</b>: {cutLength_mm.toFixed(2)} mm ({cutIntervalsAllowed} intervals @ {cutInterval_mm} mm per)</Typography>
          <Typography><b>Dead Space</b>: {(totalDeadSpace / 2).toFixed(2)} mm ({totalDeadSpace.toFixed(2)} mm total)</Typography>
        </Box>
        {
          displayedDetails &&
          <Box  my={2}>
            <Tooltip
              title={copiedDesc ? "Copied!" : "Click to copy"}
              TransitionComponent={Fade}
              TransitionProps={{
                timeout: {
                  enter: 800,
                  exit: 0,
                },
              }}
              onClose={handleOnCloseCopyTooltip}          
            >
              <Typography
                onClick={() => handleOnClickDesc(displayedDetails)}
                whiteSpace={"pre-line"}
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    color: colors.blueAccent[500],
                  },
                  display: "inline-block",
                }}
              >
                {displayedDetails}
              </Typography>
            </Tooltip>
          </Box>
        }
        <Box>
          <Box display='flex' flexDirection='row' height={'50px'} marginTop={2}>
            <Box sx={{ backgroundColor: 'red'}} width={endcapPercentage}></Box> {/* Endcap */}
            <Box sx={{ backgroundColor: '#000'}} width={deadzonePercentage}></Box> {/* Dead Zone */}
            <Box sx={{ backgroundColor: '#ffdd0075'}} width={tapePercentage}></Box> {/* Tape */}
            <Box sx={{ backgroundColor: '#000'}} width={deadzonePercentage}></Box> {/* Dead Zone */}
            <Box sx={{ backgroundColor: 'red'}} width={endcapPercentage}></Box> {/* Endcap */}
          </Box>
          <Box marginY={2}>
            <Box display='flex' flexDirection='column' gap={2}>
              <Box display='flex' flexDirection='row' gap={2}>
                <Box sx={{ backgroundColor: 'red', width: '20px', height: '20px'}}></Box>
                <Typography>Endcap</Typography>
              </Box>      
            </Box>
            <Box display='flex' flexDirection='column' gap={2}>
              <Box display='flex' flexDirection='row' gap={2}>
                <Box sx={{ backgroundColor: '#000', width: '20px', height: '20px'}}></Box>
                <Typography>Dead Zone</Typography>
              </Box>
            </Box>
            <Box display='flex' flexDirection='column' gap={2}>
              <Box display='flex' flexDirection='row' gap={2}>
                <Box sx={{ backgroundColor: '#ffdd0075', width: '20px', height: '20px'}}></Box>
                <Typography>Tape</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        </>
      }
    </Box>
  );
};

export default AssemblyHelper;