import { LedTape, LedTapeRecord, LedTapeSpecRecord } from "../database/Products";
import DeepCopy from "../utils/DeepCopy";
import { getBuildDetails, getBuildDetailsForBulkReel } from "./BuildDetails";
import { Ft2Mm, In2Ft, Mm2Ft } from "./UomConversion";

export type InFeed = 'lead' | 'fmc';

// TODO: Allow a 'Custom' distribution type, allowing to specific specific length of segments within a run.
export type CutDistribution = 'Balanced' | 'Even' | 'Maximized';

export type ForceRounding = 'Up' | 'Down';

export type LedTapeRunLine = {
  quantity: number;
  ledTapeRun: LedTapeRun;
  totalFootage: number;
  buildDetails: string;
  leadsReq: number;
  channelReq: number;
  wattsPerLead: {
    tapeLengthFt: number;
    watts: number;
  }[] | null;
  totalWatts: number;
}

export type LedTapeRun = {
  ledTape: Partial<LedTapeRecord>;
  distribution: CutDistribution;
  strips: LedTapeStrip[];
  cutIntervals: number;
  requestedLengthMM: number;
  totalLengthMm: number;
  deltaMm: number;
  specs: Partial<LedTapeSpecRecord>;
} 

export type LedTapeStrip = {
  ledTape: Partial<LedTapeRecord>;
  cutIntervals: number;
  cutLengthMm: number;
  watts: number;
}

export const CalculateRuns = (
  ledTape: LedTape, 
  requestedLengthMM: number,
  distribution: CutDistribution,
  rounding?: ForceRounding,
  ignoreMaxRunLength = false
): LedTapeRun => {
  const cutIntervalMm = ledTape.specs.cut_interval_mm ?? 1;
  const totalCutIntervals_ceil = Math.ceil(requestedLengthMM / cutIntervalMm);
  const totalCutIntervals_floor = Math.floor(requestedLengthMM / cutIntervalMm);  
  const totalRunLengthMm_ceil = totalCutIntervals_ceil * cutIntervalMm;
  const totalRunLengthMm_floor = totalCutIntervals_floor * cutIntervalMm;
  const deltaMm_ceil = requestedLengthMM - totalRunLengthMm_ceil;
  const deltaMm_floor = requestedLengthMM - totalRunLengthMm_floor;
  
  const targetDelta: 'ceil' | 'floor' = 
    rounding ? ((rounding === 'Up') ? 'ceil' : 'floor') :
    Math.abs(deltaMm_ceil) < Math.abs(deltaMm_floor) ? 'ceil' : 'floor';
  const totalCutIntervals = targetDelta === 'ceil' ? totalCutIntervals_ceil : totalCutIntervals_floor;

  const maxIntervals = !ignoreMaxRunLength ? ledTape.specs.max_cut_intervals ?? 0 : totalCutIntervals;

  if(maxIntervals === 0 || totalCutIntervals === 0){
    const run: LedTapeRun = {
      ledTape: ledTape,
      distribution: distribution,
      strips: [],
      requestedLengthMM: requestedLengthMM,
      cutIntervals: 0,
      totalLengthMm: 0,
      deltaMm: -requestedLengthMM,
      specs: ledTape.specs
    };
    return run;
  }

  switch(distribution){
    case 'Balanced': {      
      return BuildBalancedDistribution(totalCutIntervals, maxIntervals, cutIntervalMm, requestedLengthMM, ledTape);
    }
    case 'Even': {
      return BuildEvenDistribution(totalCutIntervals, maxIntervals, cutIntervalMm, requestedLengthMM, targetDelta, ledTape);      
    }
    default:  {
      return BuildMaximizedDistribution(totalCutIntervals, maxIntervals, cutIntervalMm, requestedLengthMM, ledTape);
    }
  }
}

export const BuildBalancedDistribution = (
  totalCutIntervals: number, 
  maxIntervals: number, 
  cutIntervalMm: number, 
  requestedLengthMM: number, 
  ledTape: LedTape
):LedTapeRun => {
  const stripsNeeded = Math.ceil(totalCutIntervals / maxIntervals);
  const intervalsPerStripFloat = totalCutIntervals / stripsNeeded;

  const floor_Strips = Math.floor(stripsNeeded / 2);
  const ceil_Strips = stripsNeeded - floor_Strips;
  
  const floor_Intervals = Math.floor(intervalsPerStripFloat);
  const ceil_Intervals = Math.ceil(intervalsPerStripFloat);

  const intervalsDifference = totalCutIntervals - ((floor_Strips * floor_Intervals) + (ceil_Strips * ceil_Intervals));

  const stripsInA = (floor_Strips - intervalsDifference) <= 0 ? 0 : (floor_Strips - intervalsDifference);
  const stripsInB = stripsInA <= 0 ? stripsNeeded : (ceil_Strips + intervalsDifference);
  
  const intervalsInStripA = ((totalCutIntervals > 0) && (stripsInA > 0)) ? floor_Intervals : 0;
  const intervalsInStripB = ceil_Intervals;
  const cutLengthMmA = (intervalsInStripA * cutIntervalMm);
  const cutLengthMmB = (intervalsInStripB * cutIntervalMm);

  const stripA: LedTapeStrip = {
    ledTape: ledTape,
    cutIntervals: intervalsInStripA,
    cutLengthMm: cutLengthMmA,
    watts: CalculateWattsForLedTapeStrip(cutLengthMmA, ledTape.specs)
  }
  const stripB: LedTapeStrip = {
    ledTape: ledTape,
    cutIntervals: intervalsInStripB,
    cutLengthMm: cutLengthMmB,
    watts: CalculateWattsForLedTapeStrip(cutLengthMmB, ledTape.specs)
  }
  const strips: LedTapeStrip[] = Array.from({ length: stripsInA }, () => stripA);
  strips.push(...Array.from({ length: stripsInB }, () => stripB));

  const debug = false;
  if(debug){
    console.log(
      '=======================================' + '\n' + 
      'totalCutIntervals ' + totalCutIntervals + '\n' +
      '\n' +
      'strips needed ' + stripsNeeded + '\n' +
      'intervalsPerStripFloat ' + intervalsPerStripFloat + '\n' +
      '\n' +
      'floor intervals ' + floor_Intervals.toString() + '\n' +
      'ceil intervals ' + ceil_Intervals.toString() + '\n' +
      '\n' +
      'floor strips ' + floor_Strips.toString() + '\n' +
      'ceil strips ' + ceil_Strips.toString() + '\n' +
      '\n' +
      'int diff ' + intervalsDifference.toString() + '\n' +
      'intervalsInStripA ' + intervalsInStripA + '\n' + 
      'intervalsInStripB ' + intervalsInStripB + '\n' +
      'intervals after calc ' + strips.map(s=>s.cutIntervals).reduce((curr, prev) => curr + prev, 0).toString() + '\n'
    );
  }
  
  if(totalCutIntervals.toString() !== strips.map(s=>s.cutIntervals).reduce((curr, prev) => curr + prev, 0).toString()){
    console.error(
      '=========' + '\n' + 
      'A: ' + stripsInA + "x " + intervalsInStripA.toString() + '\n' + 
      'B: ' + stripsInB + "x " + intervalsInStripB.toString() + '\n' + 
      '---' + '\n' + 
      'display: ' + totalCutIntervals.toString() + '\n' + 
      'actual : ' + strips.map(s=>s.cutIntervals).reduce((curr, prev) => curr + prev, 0).toString() + '\n' + 
      '========='
    );
  }

  return {
    ledTape: ledTape,
    distribution: 'Balanced',
    strips: strips,
    requestedLengthMM: requestedLengthMM,
    cutIntervals: totalCutIntervals,
    totalLengthMm: totalCutIntervals * cutIntervalMm,
    deltaMm: (totalCutIntervals * cutIntervalMm) - requestedLengthMM,
    specs: ledTape.specs
  };
}

export const BuildEvenDistribution = (
  totalCutIntervals: number, 
  maxIntervals: number, 
  cutIntervalMm: number, 
  requestedLengthMM: number,
  targetDelta: 'ceil' | 'floor',
  ledTape: LedTape
):LedTapeRun => {
  const stripsNeeded = Math.ceil(totalCutIntervals / maxIntervals);
  const intervalsPerStrip =
    stripsNeeded <= 0 ? 0 : (
      targetDelta === 'ceil' ? 
      Math.ceil(totalCutIntervals / stripsNeeded) : Math.floor(totalCutIntervals / stripsNeeded)
    )
  const lengthPerStripMm = intervalsPerStrip * cutIntervalMm;
  const stripInstance: LedTapeStrip = {
    ledTape: ledTape,
    cutIntervals: intervalsPerStrip,
    cutLengthMm: lengthPerStripMm,
    watts: CalculateWattsForLedTapeStrip(lengthPerStripMm, ledTape.specs)
  };
  const strips: LedTapeStrip[] = Array.from({ length: stripsNeeded }, () => DeepCopy(stripInstance));
  return {
    ledTape: ledTape,
    distribution: 'Even',
    strips: strips,
    requestedLengthMM: requestedLengthMM,
    cutIntervals: intervalsPerStrip * stripsNeeded,
    totalLengthMm: (intervalsPerStrip * stripsNeeded) * cutIntervalMm,
    deltaMm: (totalCutIntervals * cutIntervalMm) - requestedLengthMM,
    specs: ledTape.specs
  };
}

export const BuildMaximizedDistribution = (
  totalCutIntervals: number, 
  maxIntervals: number, 
  cutIntervalMm: number, 
  requestedLengthMM: number,
  ledTape: LedTape
):LedTapeRun => {
  const fullStripsNeeded = Math.floor(totalCutIntervals / maxIntervals);
  const stripInstance: LedTapeStrip = {
    ledTape: ledTape,
    cutIntervals: maxIntervals,
    cutLengthMm: (maxIntervals * cutIntervalMm),
    watts: CalculateWattsForLedTapeStrip((maxIntervals * cutIntervalMm), ledTape.specs)
  };
  const strips: LedTapeStrip[] = Array.from({ length: fullStripsNeeded }, () => DeepCopy(stripInstance));
  const remainderIntervals = totalCutIntervals - (fullStripsNeeded * maxIntervals);
  const remainderStrip: LedTapeStrip = {
    ledTape: ledTape,
    cutIntervals: remainderIntervals,
    cutLengthMm: (remainderIntervals * cutIntervalMm),
    watts: CalculateWattsForLedTapeStrip((remainderIntervals * cutIntervalMm), ledTape.specs)
  };
  if(remainderIntervals > 0) strips.push(remainderStrip);
  const totalLengthMm = totalCutIntervals * cutIntervalMm;
  return {
    ledTape: ledTape,
    distribution: 'Maximized',
    strips: strips,
    requestedLengthMM: requestedLengthMM,
    cutIntervals: totalCutIntervals,
    totalLengthMm: totalLengthMm,
    deltaMm: totalLengthMm - requestedLengthMM,
    specs: ledTape.specs
  };
}

export const CalculateWattsForLedTapeStrip = (cutLengthMm: number, specs: Partial<LedTapeSpecRecord>) => {
  const a = specs.a ?? 0;
  const b = specs.b ?? 0;
  const c = specs.c ?? 0;

  // Wattage Per Length = (A * (Length)^2) + (B * Length) + C)
  const lengthFt = Mm2Ft(cutLengthMm);
  const watts = a * (lengthFt * lengthFt) + b * lengthFt + c;
  return watts;
}

export const BuildLedTapeRunLine = (ledTapeRun: LedTapeRun, qty: number, wattsInDetails: boolean, inFeed: InFeed): LedTapeRunLine => {
  const totalFootage = Mm2Ft(ledTapeRun.totalLengthMm) * qty;
  const buildDetails = (qty > 0) ? getBuildDetails(qty, ledTapeRun.specs.cut_interval_mm ?? 0, ledTapeRun.strips, wattsInDetails) : '';
  const leadsRequired = inFeed === 'lead' ? ledTapeRun.strips.length * qty : 0;
  const channelRequired = Math.ceil(Mm2Ft(ledTapeRun.totalLengthMm) / 6.56) * qty;

  const totalWatts = ledTapeRun.strips.map((strip) => strip.watts)
  .reduce((accumulator, currValue) =>  accumulator + currValue, 0) * qty;

  const uniqueLengthsMm = new Set<number>(ledTapeRun.strips.map(s => s.cutLengthMm));
  const uniqueWattsPerFt = Array.from(uniqueLengthsMm).map(len => ({
    tapeLengthFt: Number(Mm2Ft(len).toFixed(2)),
    watts: ledTapeRun.strips.find(s => s.cutLengthMm === len)?.watts ?? 0
  }));

  const wattsPerLead = ledTapeRun.strips[0] ? uniqueWattsPerFt : null;

  return {
    quantity: qty,
    ledTapeRun: ledTapeRun,
    totalFootage: totalFootage,
    buildDetails: buildDetails,
    channelReq: channelRequired,
    leadsReq: leadsRequired,
    totalWatts: totalWatts,
    wattsPerLead: wattsPerLead
  }
}

export const BuildLedTapeBulkReelLine = (ledTape: LedTape, qty: number): LedTapeRunLine => {
  const totalLength = (ledTape.specs.bulk_reel_length ?? 0) * qty;
  const uom = ledTape.specs.bulk_reel_uom;
  const totalFootage = uom === 'IN' ? In2Ft(totalLength) : uom === 'MM' ? Mm2Ft(totalLength) : totalLength;
  const buildDetails = (qty > 0) ? getBuildDetailsForBulkReel((totalFootage / qty), qty) : '';
  const channelRequired = Math.ceil(totalFootage / 6.56);
  const totalLengthMm = Ft2Mm(totalLength);
  const ledTapeRun: LedTapeRun = {
    ledTape: ledTape,
    cutIntervals: 0,
    deltaMm: 0,
    distribution: 'Balanced',
    requestedLengthMM: totalLengthMm,
    specs: ledTape.specs,
    strips: [],
    totalLengthMm: totalLengthMm
  };
  return {
    quantity: qty,
    ledTapeRun: ledTapeRun,
    totalFootage: totalFootage,
    buildDetails: buildDetails,
    channelReq: channelRequired,
    leadsReq: 0,
    totalWatts: 0,
    wattsPerLead: null
  }
}