import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { Container } from "@mui/system";
import Header from "../../../components/Header";
import { hasPermission } from "../../../database/Permissions";
import StyledTextField from "../../../components/StyledTextField";
import { BuildLedTapeFromOptionCodes, GetAllLedTapesWithOptionsAndSpecs, GetAllPossibleLedTapeSkus, LedTape, LedTapeWithOptionsAndSpecs } from "../../../database/Products";
import { SelectField } from "../../../components/TextField";
import { getChannelData } from "./data";
import RunsTable from "./components/RunsTable";
import TapeSpecs from "./components/TapeSpecs";
import { ChannelAssembly } from "./types";
import CompatibleChannel from "./components/CompatibleChannel";
import RenderOptionValueListItem from "./components/OptionValueListItem";
import AssemblyHelper from "./components/AssemblyHelper";
import StyledTabPanel from "../../../components/StyledTabPanel";

const Tapetool = () => {
  const colors = useTheme().colors;
  const profile = useSelector((state: RootState) => state.profile);
  if (!hasPermission(profile, "TOOL_TAPETOOL")) window.location.href = "/";

  const [ledTapeData, setLedTapeData] = useState<LedTapeWithOptionsAndSpecs[] | null>( null);
  const [ledTapeSkus, setLedTapeSkus] = useState<{ specId: number; sku: string; }[]>([]);
  
  const [searchSkuInput, setSearchSkuInput] = useState<string>('');
  const [selectedSkuStr, setSelectedSkuStr] = useState<string | null>(null);

  const [tapeSearchInput, setTapeSearchInput] = useState<string>("");
  const [selectedTapeName, setSelectedTapeName] = useState<string | null>(null);

  const [colorInput, setColorInput] = useState<number | null>(null);
  const [kelvinTempInput, setKelvinTempInput] = useState<number | null>(null);
  const [lumensInput, setLumensInput] = useState<number | null>(null);
  const [sdcmInput, setSdcmInput] = useState<number | null>(null);

  const [selectedLedTape, setSelectedLedTape] = useState<LedTape | null>(null);

  const [channelsData, setChannelsData] = useState<ChannelAssembly[] | null>( null);

  const [tabValue, setTabValue] = useState<number>(0);

  const queryData = useCallback(async () => {
    const [tapeData, channelData] = await Promise.all([
      GetAllLedTapesWithOptionsAndSpecs(true),
      getChannelData(),
    ]);
    setLedTapeData(tapeData.sort((a, b) => a.name.localeCompare(b.name)));
    setChannelsData(channelData);
    const uniTape = tapeData.find(tape => tape.name.includes('Uniform Static White Light') && tape.location === 'Indoor' );
    if(uniTape || tapeData.length > 0) setSelectedTapeName(uniTape?.name ?? tapeData[0].name);
    const skuCodes = tapeData.flatMap(d => {
      return GetAllPossibleLedTapeSkus(d);
    });
    setLedTapeSkus(skuCodes.sort((a, b) => a.sku.localeCompare(b.sku)));
  }, []);

  useEffect(() => {
    queryData();
  }, []);

  const targetLedTapeData = ledTapeData?.find(ltd => ltd.name === selectedTapeName);

  const conflictIds: number[] = [];
  targetLedTapeData?.options.forEach(opt => {
    if(![colorInput, kelvinTempInput, lumensInput, sdcmInput].includes(opt.id)) return;
    opt.conflicts.forEach(conflict => conflictIds.push(conflict.option_value_id_2));
  });

  const colorOptions = targetLedTapeData?.options.filter(opt => opt.type === 'Color');
  const kelvinTempOptions = targetLedTapeData?.options.filter(opt => opt.type === 'Kelvin Temp');
  const lumensOptions = targetLedTapeData?.options.filter(opt => opt.type === 'Lumens');
  const sdcmOptions = targetLedTapeData?.options.filter(opt => opt.type === 'SDCM');

  const selectedColor = colorOptions?.find(color => color.id === colorInput);
  const selectedKelvinTemp = kelvinTempOptions?.find(kelvinTemp => kelvinTemp.id === kelvinTempInput);
  const selectedLumens = lumensOptions?.find(lumens => lumens.id === lumensInput);
  const selectedSdcm = sdcmOptions?.find(sdcm => sdcm.id === sdcmInput);

  // Set selected tape
  useEffect(() => {
    const targetTape = targetLedTapeData ? BuildLedTapeFromOptionCodes(
      targetLedTapeData, 
      {
        colorValue: colorOptions?.find(opt => opt.id === colorInput)?.sku_code ?? '',
        kelvinTempValue: kelvinTempOptions?.find(opt => opt.id === kelvinTempInput)?.sku_code ?? '',
        lumensValue: lumensOptions?.find(opt => opt.id === lumensInput)?.sku_code ?? '',
        sdcmValue: sdcmOptions?.find(opt => opt.id === sdcmInput)?.sku_code ?? ''
      }
    ) : null;
    setSelectedLedTape(targetTape);
  }, [selectedTapeName, colorInput, kelvinTempInput, lumensInput, sdcmInput]);

  // Reset option inputs
  useEffect(() => {
    const selectedLedTapeData = ledTapeData?.find(ltd => ltd.name === selectedTapeName);
    if(!selectedLedTapeData){
      setColorInput(null);
      setKelvinTempInput(null);
      setLumensInput(null);
      setSdcmInput(null);
      return;
    }
    const colorOptions = selectedLedTapeData?.options.filter(opt => opt.type === 'Color') ?? [];
    const kelvinTempOptions = selectedLedTapeData?.options.filter(opt => opt.type === 'Kelvin Temp') ?? [];
    const lumensOptions = selectedLedTapeData?.options.filter(opt => opt.type === 'Lumens') ?? [];
    const sdcmOptions = selectedLedTapeData?.options.filter(opt => opt.type === 'SDCM') ?? [];
    const defaultColor = colorOptions.find(opt => opt.default);
    const defaultKelvinTemp = kelvinTempOptions.find(opt => opt.default);
    const defaultLumens = lumensOptions.find(opt => opt.default);
    const defaultSdcm = sdcmOptions.find(opt => opt.default);
    setColorInput((colorOptions.length > 0) ? (defaultColor?.id ?? colorOptions[0].id) : null);
    setKelvinTempInput((kelvinTempOptions.length > 0) ? (defaultKelvinTemp?.id ?? kelvinTempOptions[0].id) : null);
    setLumensInput((lumensOptions.length > 0) ? (defaultLumens?.id ?? lumensOptions[0].id) : null);
    setSdcmInput((sdcmOptions.length > 0) ? (defaultSdcm?.id ?? sdcmOptions[0].id) : null);
  }, [selectedTapeName]);

  // Set SKU
  useEffect(() => {
    if(!selectedTapeName) {
      setSelectedSkuStr(null);
      return;
    }
    const selectedLedTapeData = ledTapeData?.find(ltd => ltd.name === selectedTapeName);
    if(!selectedLedTapeData) return;
    const spec = selectedLedTapeData.specs.find(spec => 
      spec.option_color === colorInput &&
      spec.option_kelvin_temp === kelvinTempInput &&
      spec.option_lumens === lumensInput &&
      spec.option_sdcm === sdcmInput
    );
    if(!spec) return;
    const foundSku = ledTapeSkus.find(sku => sku.specId === spec.id);
    if(!foundSku) return;
    if(foundSku.sku === selectedSkuStr) return;
    setSelectedSkuStr(foundSku?.sku);
  }, [selectedTapeName, colorInput, kelvinTempInput, lumensInput, sdcmInput]);

  // Set tape name and option inputs
  useEffect(() => {
    ledTapeData?.forEach(ltd => {
      const allPossibleSkus = GetAllPossibleLedTapeSkus(ltd);
      const matchedSku = allPossibleSkus.find(sku => sku.sku === selectedSkuStr);
      if(matchedSku){
        setSelectedTapeName(ltd.name);
        const matchedSpec = ltd.specs.find(spec => spec.id === matchedSku.specId);
        setColorInput(matchedSpec?.option_color ?? null);
        setKelvinTempInput(matchedSpec?.option_kelvin_temp ?? null);
        setLumensInput(matchedSpec?.option_lumens ?? null);
        setSdcmInput(matchedSpec?.option_sdcm ?? null);
      }
    });
  }, [selectedSkuStr]);

  const handleChangeTapeSeries = (newValue: string | null) => {
    setSelectedTapeName(newValue);
  }

  const handleChangeTab = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    setTabValue(newValue);
  }

  return (
    <Box m="20px">
      <Header
        title="TAPE TOOL"
        subtitle="A tool to generate build details for standard linear LED Tape."
      />
      <Container style={{ padding: "20px", maxWidth: "1400px" }}>
        {
          (!ledTapeData || !channelsData) &&
          <CircularProgress />
        }
        {
          ledTapeData && channelsData &&
          <>
          <Box display='flex' flexDirection='row' gap={2} alignItems='flex-end'>
            <Autocomplete
              disablePortal
              id="skuLookup"
              options={ledTapeSkus.map((v) => v.sku)}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label="SKU Lookup"
                />
              )}
              value={selectedSkuStr}
              onChange={(event, newValue) => setSelectedSkuStr(newValue)}
              inputValue={searchSkuInput}
              onInputChange={(event, newValue) => setSearchSkuInput(newValue)}
              size="small"
              sx={{
                minWidth: '225px',
                "& label.Mui-focused": {
                  color: colors.blueAccent[500],
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: colors.blueAccent[500],
                  },
                },
              }}
            />
            <Autocomplete
              disablePortal
              id="tapeSeries"
              options={ledTapeData.map((v) => v.name)}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label="Tape Series"
                />
              )}
              value={selectedTapeName}
              onChange={(event, newValue) => handleChangeTapeSeries(newValue)}
              inputValue={tapeSearchInput}
              onInputChange={(event, newValue) => setTapeSearchInput(newValue)}
              size="small"
              sx={{
                minWidth: '275px',
                "& label.Mui-focused": {
                  color: colors.blueAccent[500],
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    borderColor: colors.blueAccent[500],
                  },
                },
              }}
            />
            {
              colorOptions && colorOptions.length > 0 &&
              <Box minWidth='100px'>
                <SelectField 
                  label='Color'
                  value={colorInput ?? ''}
                  onChange={(e) => setColorInput(Number(e.target.value))}
                  variant="outlined"
                  fullWidth
                >
                  {
                    colorOptions.map(color =>  RenderOptionValueListItem(
                      color.id, color, selectedColor, selectedKelvinTemp, selectedLumens, selectedSdcm, conflictIds
                    ))
                  }
                </SelectField>
              </Box>
            }
            {
              sdcmOptions && sdcmOptions.length > 0 &&
              <Box minWidth='100px'>
                <SelectField 
                  label='SDCM'
                  value={sdcmInput ?? ''}
                  onChange={(e) => setSdcmInput(Number(e.target.value))}
                  variant="outlined"
                  fullWidth
                >
                  {
                    sdcmOptions.map(sdcm => RenderOptionValueListItem(
                      sdcm.id, sdcm, selectedColor, selectedKelvinTemp, selectedLumens, selectedSdcm, conflictIds
                    ))
                  }
                </SelectField>
              </Box>
            }
            {
              lumensOptions && lumensOptions.length > 0 &&
              <Box minWidth='100px'>
                <SelectField 
                  label='Lumens'
                  value={lumensInput ?? ''}
                  onChange={(e) => setLumensInput(Number(e.target.value))}
                  variant="outlined"
                  fullWidth
                >
                  {
                    lumensOptions.map(lumens =>  RenderOptionValueListItem(
                      lumens.id, lumens, selectedColor, selectedKelvinTemp, selectedLumens, selectedSdcm, conflictIds
                    ))
                  }
                </SelectField>
              </Box>
            }
            {
              kelvinTempOptions && kelvinTempOptions.length > 0 &&
              <Box minWidth='100px'>
                <SelectField 
                  label='Kelvin Temp'
                  value={kelvinTempInput ?? ''}
                  onChange={(e) => setKelvinTempInput(Number(e.target.value))}
                  variant="outlined"
                  fullWidth
                >
                  {
                    kelvinTempOptions.map(kelvinTemp =>  RenderOptionValueListItem(
                      kelvinTemp.id, kelvinTemp, selectedColor, selectedKelvinTemp, selectedLumens, selectedSdcm, conflictIds
                    ))
                  }
                </SelectField>
              </Box>
            }
          </Box>

          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            textColor="secondary"
            indicatorColor="secondary"
          >
            <Tab label="Run Builder" value={0} />
            <Tab label="Assembly Helper" value={1} />
          </Tabs>
          <Divider />
          <StyledTabPanel value={tabValue} index={0} paddingX={0}>
            <RunsTable ledTape={selectedLedTape} />
          </StyledTabPanel>
          <StyledTabPanel value={tabValue} index={1}>
            <AssemblyHelper ledTape={selectedLedTape} />
          </StyledTabPanel>

          <Box
            display="flex"
            flexDirection='row'
            gap={2}
            pt={2}
          >
            {
              selectedLedTape && 
              <TapeSpecs ledTape={selectedLedTape} />
            }
            {
              selectedLedTape && 
              <CompatibleChannel
                ledTape={selectedLedTape}
                channelAssemblies={channelsData}
              />
            }
          </Box>
          </>
        }
      </Container>
    </Box>
  );
};

export default Tapetool;
